import PropTypes from "prop-types"
import React from "react"
import Image from "./image"
import Logo from "./logo"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `transparent`,
      marginBottom: `1.45rem`,
      maxHeight: `600px`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 99,
          left: "50%",
          top: "2rem",
          transform: "translate(-50%)",
          width: "50%",
        }}
      >
        <a href="https://www.fccgranbury.org" style={{}}>
          <Logo />
        </a>
      </div>
    </div>
    <div className="banner">
      <Image />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
